import axios from "axios";
import {getRecaptchaToken} from "../helpers/reCAPTCHA.js";
import {listenToHideToast, showToast, timeoutHideToast} from "./blade-toast.js";
import {sendGtag} from "../helpers/GoogleAnalytics.js";

window.axios = axios;
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.defaults.withCredentials = true;

const contactForm = document.getElementById("contactForm");
const ebookForm = document.getElementById("ebookForm");
const emailInput = document.getElementById("email");
const ebookSuccess = document.getElementById("ebookSuccess");
const referrer = document.getElementById("referrer");

if (emailInput && ebookForm) {
  emailInput.addEventListener("input", () => {
    if (emailInput.value.length) {
      ebookForm.querySelector("button").classList.remove("opacity-50");
      emailInput.classList.remove("bg-opacity-70");
      emailInput.classList.add("bg-opacity-100");
    } else {
      ebookForm.querySelector("button").classList.add("opacity-50");
      emailInput.classList.remove("bg-opacity-100");
      emailInput.classList.add("bg-opacity-70");
    }
  });
}

if (ebookForm) {
  setTimeout(() => {
    ebookForm.classList.remove("opacity-0");
    ebookForm.classList.remove("scale-75");
  }, 50);

  ebookForm.addEventListener("submit", async function (event) {
    event.preventDefault();
    event.stopImmediatePropagation();

    let email = emailInput.value;
    if (!email) return;

    const recaptchaToken = await getRecaptchaToken("emaillist");

    await axios.post("/api/email-list", {
      email: email,
      sent_hiring_ebook: ebookForm.getAttribute("data-send-ebook") === "1",
      referrer: referrer ? referrer.value : "",
      "g-recaptcha-response": recaptchaToken,
    })
      .then(() => {
        sendGtag("event", "lp_ebook_form");
        showToast(ebookForm.getAttribute("data-success-title"), ebookForm.getAttribute("data-success-description"));
        timeoutHideToast(3000);
        emailInput.value = "";
      })
      .catch((error) => {
        const emailError = error.response.data.errors.email && error.response.data.errors.email.length ? error.response.data.errors.email[0] : "";
        const recaptchaError = error.response.data.errors["g-recaptcha-response"] && error.response.data.errors["g-recaptcha-response"].length ? "Our robot detector went off! Please try again." : "";
        const errorMessage = emailError || recaptchaError;

        showToast("Hmm... something's not right.", errorMessage, "#EF4444");
        timeoutHideToast(3000);
      });
  });
}

if (contactForm) {
  contactForm.addEventListener("submit", async function (event) {
    event.preventDefault();
    event.stopImmediatePropagation();

    const formData = new FormData(contactForm);
    const recaptchaToken = await getRecaptchaToken("contact");

    formData.append("g-recaptcha-response", recaptchaToken);

    await axios.post("/api/contact-us", formData)
      .then(() => {
        showToast(contactForm.getAttribute("data-success-title"), contactForm.getAttribute("data-success-description"));
        timeoutHideToast(3000);
        contactForm.reset();
      })
      .catch((error) => {
        const nameError = error.response.data.errors.name && error.response.data.errors.name.length ? error.response.data.errors.name[0] : "";
        const emailError = error.response.data.errors.email && error.response.data.errors.email.length ? error.response.data.errors.email[0] : "";
        const messageError = error.response.data.errors.message && error.response.data.errors.message.length ? error.response.data.errors.message[0] : "";
        const recaptchaError = error.response.data.errors["g-recaptcha-response"] && error.response.data.errors["g-recaptcha-response"].length ? "Our robot detector went off! Please try again." : "";
        const errorMessage = nameError || emailError || messageError || recaptchaError;

        showToast("Hmm... something's not right.", errorMessage, "#EF4444");
        timeoutHideToast(3000);
      });
  });
}

if (ebookSuccess) {
  showToast("Success – Email Sent 🎉", "We've just sent you an email with the download link!");
}

listenToHideToast();
