const testimonialBtns = document.querySelectorAll("#testimonialButtons .testimonial-button");
const prevTestimonialBtn = document.getElementById("prevTestimonialBtn");
const nextTestimonialBtn = document.getElementById("nextTestimonialBtn");
const testimonials = document.querySelectorAll("#testimonialContainer .testimonial");

let currentTestimonialIndex = 0;

const setCurrentTestimonial = (index) => {
  if (index === currentTestimonialIndex) return;
  currentTestimonialIndex = index;

  testimonialBtns.forEach((button, i) => {
    const isActive = i === index;
    button.classList.toggle("opacity-20", !isActive);
    button.classList.toggle("hover:opacity-40", !isActive);
  });

  testimonials.forEach((testimonial, i) => {
    const isActive = i === index;
    testimonial.classList.toggle("opacity-0", !isActive);
    testimonial.classList.toggle("pointer-events-none", !isActive);

    let translateX, translateY, rotate, scale;

    if (isActive) {
      translateX = "0";
      translateY = "0";
      rotate = "0deg";
      scale = "1";
    } else if (i < index) { // To the left
      const distance = index - i;
      translateX = `-${100 * distance}%`;
      translateY = `${100 * distance}px`;
      rotate = `-${10 + distance}deg`;
      scale = .5;
    } else { // To the right
      const distance = i - index;
      translateX = `${100 * distance}%`;
      translateY = `-${100 * distance}px`;
      rotate = `${10 + distance}deg`;
      scale = .5;
    }

    testimonial.style.transform = `translateX(${translateX}) translateY(${translateY}) rotate(${rotate}) scale(${scale})`;
  });
};

const prevTestimonial = () => {
  if (currentTestimonialIndex === 0) setCurrentTestimonial(testimonials.length - 1);
  else setCurrentTestimonial(currentTestimonialIndex - 1);
};

const nextTestimonial = () => {
  if (currentTestimonialIndex === testimonials.length - 1) setCurrentTestimonial(0);
  else setCurrentTestimonial(currentTestimonialIndex + 1);
};

/* ------------------------------------------------------------------
                          Event Listeners
 ------------------------------------------------------------------ */

if (testimonialBtns.length > 0) {
  testimonialBtns.forEach((button, index) => button.addEventListener("click", () => setCurrentTestimonial(index)));
}

if (prevTestimonialBtn) prevTestimonialBtn.addEventListener("click", prevTestimonial);
if (nextTestimonialBtn) nextTestimonialBtn.addEventListener("click", nextTestimonial);
