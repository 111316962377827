import {debounce} from "./lightweight-debounce.js";

const platformFeatures = document.querySelector(".platform-features");
const platformFeaturesPlayPauseBtn = document.getElementById("platformFeaturesPlayPauseBtn");
const onlyToolWord = document.getElementById("onlyToolWord");
const onlyToolMark = document.getElementById("onlyToolMark");
const tryFreePlatformBtn = document.getElementById("tryFreePlatformBtn");
const platformFeatureBtns = document.querySelectorAll(".platform-feature-btn");
const platformFeaturePause = document.querySelectorAll(".platform-feature-pause");
const platformFeaturePlay = document.querySelectorAll(".platform-feature-play");

let isPlatformFeaturesAutoplayRunning = false;
let platformFeaturesCurrentIndex = 0;
let platformFeaturesCurrentSelector = null;
let autoplayInterval = null;
const autoplayDuration = 6000;
const featureTransitionTranslateYAmountPx = 40;
const featureTransitionDuration = 500;

const updatePlatformFeaturesDisplay = (index) => {
  platformFeatureBtns.forEach((btn, btnIndex) => {
    const targetSelector = btn.getAttribute("data-target-selector");
    const targetElements = document.querySelectorAll(targetSelector);

    const dataHiddenClass = btn.getAttribute("data-hidden-class") || "lg:hidden";

    // for each target element
    targetElements.forEach((targetElement) => {
      const dataAnimationDelay = parseInt(targetElement.dataset?.animationDelayMs || 0);

      if (btnIndex === index) {
        // Incoming card animation, delayed to start after the outgoing card completes
        setTimeout(() => {
          if (platformFeaturesCurrentSelector !== targetSelector) return;

          // Ensure the element is in the starting position
          targetElement.classList.remove(dataHiddenClass);
          targetElement.style.opacity = "0";
          targetElement.style.transform = `translateY(${featureTransitionTranslateYAmountPx}px)`;

          // Trigger reflow/repaint to ensure the styles are applied before the transition
          targetElement.offsetHeight; // This forces the browser to apply the current styles before transitioning

          // Apply the transition styles
          targetElement.style.transition = `opacity ${featureTransitionDuration}ms ease-in-out, transform ${featureTransitionDuration}ms ease-in-out`;
          targetElement.style.opacity = "1";
          targetElement.style.transform = "translateY(0)";

          btn.classList.add(...btn.getAttribute("data-selected").split(" "));
          btn.classList.remove(...btn.getAttribute("data-not-selected").split(" "));
          if (onlyToolWord) onlyToolWord.className = "";
          if (onlyToolWord) onlyToolWord.classList.add(...btn.getAttribute("data-only-tool-color").split(" "));
          if (onlyToolMark) onlyToolMark.style.setProperty("--color-hex", btn.getAttribute("data-only-tool-circle-color"));
          if (tryFreePlatformBtn) {
            const colors = ["btn-match", "btn-connect", "btn-hire"];
            colors.forEach(color => tryFreePlatformBtn.classList.remove(color));
            const newClasses = btn.getAttribute("data-platform-features-btn").split(" ");
            newClasses.forEach(newClass => tryFreePlatformBtn.classList.add(newClass));
          }

        }, featureTransitionDuration + dataAnimationDelay); // Delay by the duration of the outgoing animation

      } else {
        // Outgoing card animation
        targetElement.style.transition = `opacity ${featureTransitionDuration}ms ease-in-out, transform ${featureTransitionDuration}ms ease-in-out`;
        targetElement.style.opacity = "1";
        targetElement.style.transform = "translateY(0)";

        // Ensure the animation starts
        requestAnimationFrame(() => {
          if (platformFeaturesCurrentSelector === targetSelector) return;
          targetElement.style.opacity = "0";
          targetElement.style.transform = `translateY(-${featureTransitionTranslateYAmountPx}px)`;
        });

        // Hide the element after the animation completes
        setTimeout(() => {
          if (platformFeaturesCurrentSelector === targetSelector) return;
          targetElement.classList.add(dataHiddenClass);
        }, featureTransitionDuration);

        btn.classList.add(...btn.getAttribute("data-not-selected").split(" "));
        btn.classList.remove(...btn.getAttribute("data-selected").split(" "));
      }
    });
  });
};


const startFeatureAutoplay = () => {
  if (isPlatformFeaturesAutoplayRunning || window.innerWidth < 1024) return;
  isPlatformFeaturesAutoplayRunning = true;
  platformFeaturePlay.forEach(btn => btn.classList.add("hidden"));
  platformFeaturePause.forEach(btn => btn.classList.remove("hidden"));

  autoplayInterval = setInterval(() => {
    platformFeaturesCurrentIndex = (platformFeaturesCurrentIndex + 1) % platformFeatureBtns.length;
    platformFeaturesCurrentSelector = platformFeatureBtns[platformFeaturesCurrentIndex].getAttribute("data-target-selector");
    updatePlatformFeaturesDisplay(platformFeaturesCurrentIndex);
  }, autoplayDuration);
};

const pauseFeatureAutoplay = () => {
  if (!isPlatformFeaturesAutoplayRunning) return;
  isPlatformFeaturesAutoplayRunning = false;
  platformFeaturePlay.forEach(btn => btn.classList.remove("hidden"));
  platformFeaturePause.forEach(btn => btn.classList.add("hidden"));

  clearInterval(autoplayInterval);
};

const toggleFeatureAutoplay = () => {
  if (isPlatformFeaturesAutoplayRunning) pauseFeatureAutoplay();
  else startFeatureAutoplay();
};

const handleFeatureBtnClick = (index) => {
  platformFeaturesCurrentIndex = index;
  platformFeaturesCurrentSelector = platformFeatureBtns[index].getAttribute("data-target-selector");
  updatePlatformFeaturesDisplay(platformFeaturesCurrentIndex);

  pauseFeatureAutoplay(); // Pause autoplay when a feature button is clicked
};

platformFeatureBtns.forEach((btn, index) => {
  btn.addEventListener("click", () => handleFeatureBtnClick(index));
});

const initPlatformFeatures = () => {
  platformFeaturesCurrentSelector = platformFeatureBtns[platformFeaturesCurrentIndex].getAttribute("data-target-selector");
  if (window.innerWidth >= 1024) updatePlatformFeaturesDisplay(platformFeaturesCurrentIndex);
};

const platformFeatureObserverOptions = {
  root: null,
  threshold: 0.05
};

const platformFeatureObserverCallback = (entries) => {
  entries.forEach(entry => {
    if (window.innerWidth < 1024) return;
    if (entry.isIntersecting) startFeatureAutoplay();
    else pauseFeatureAutoplay();
  });
};

const handleResize = () => {
  if (window.innerWidth < 1024) pauseFeatureAutoplay();
  else if (!isPlatformFeaturesAutoplayRunning) startFeatureAutoplay();

  platformFeatureBtns.forEach((btn) => {
    const targetSelector = btn.getAttribute("data-target-selector");
    const targetElements = document.querySelectorAll(targetSelector);

    const isMobile = window.innerWidth < 1024;

    for (let i = 0; i < targetElements.length; i++) {
      const targetElement = targetElements[i];

      if (isMobile) {
        const currentTransition = targetElement.style.transition || targetElement.dataset.transition;
        const currentOpacity = targetElement.style.opacity || targetElement.dataset.opacity;
        const currentTransform = targetElement.style.transform || targetElement.dataset.transform;

        targetElement.dataset.transition = currentTransition;
        targetElement.dataset.opacity = currentOpacity;
        targetElement.dataset.transform = currentTransform;

        targetElement.style.transition = "";
        targetElement.style.opacity = "";
        targetElement.style.transform = "";
      } else {
        const currentTransition = targetElement.style.transition || targetElement.dataset.transition;
        const currentOpacity = targetElement.style.opacity || targetElement.dataset.opacity;
        const currentTransform = targetElement.style.transform || targetElement.dataset.transform;

        targetElement.style.transition = currentTransition;
        targetElement.style.opacity = currentOpacity;
        targetElement.style.transform = currentTransform;
      }
    }
  });
};

const debounceHandleResize = debounce(handleResize, 100);

if (platformFeatures) {
  initPlatformFeatures();
  const observer = new IntersectionObserver(platformFeatureObserverCallback, platformFeatureObserverOptions);
  observer.observe(platformFeatures);
  window.addEventListener("resize", debounceHandleResize);
  document.addEventListener("visibilitychange", () => {
    if (document.visibilityState === "hidden") pauseFeatureAutoplay();
    else startFeatureAutoplay();
  });
  platformFeaturesPlayPauseBtn.addEventListener("click", toggleFeatureAutoplay);
}
