const navToggles = document.querySelectorAll(".nav-toggle");
const menuFull = document.getElementById("menuFull");
const navToggleSr = document.getElementById("navToggleSr");
const menuFullLinkLis = document.querySelectorAll("#menuFull li");
const menuFullLinks = document.querySelectorAll("#menuFull a");
const menuCtas = document.getElementById("menuCtas");
const mainNav = document.getElementById("mainNav");
const scrollNav = document.getElementById("scrollNav");
const mainNavRingOffsetBlack = document.querySelectorAll("#mainNav .ring-offset-black");

// NEW NAVBAR //
const dropdownTriggers = document.getElementsByClassName("dropdown-trigger");
const dropdownBackground = document.getElementById("dropdownBackground");
const dropdownClickables = document.querySelectorAll(".group\\/dropdown a, .group\\/dropdown button");
const DROPDOWN_TIMEOUT_DURATION = 300;
const MAIN_NAV_SCROLL_THRESHOLD = 250;

let openedDropdownAt = null;
let openDropdownId = null;
let hideDropdownTimeout = null;
let menuColorIsLight = false;
let menuScrolledPastThreshold = false;
// END NEW NAVBAR //

let isMenuOpen = false;
let initialMenuStrokesColor = "#000";

const navToggleOpenClasses = ["menu-open", "fixed", "z-max", "right-4"];

const toggleNav = () => {
  isMenuOpen = !isMenuOpen;

  if (isMenuOpen) {
    mainNav.classList.remove("z-50");
    mainNav.classList.add("z-max-1");
    if (scrollNav) scrollNav.classList.add("hidden");
    if (dropdownBackground) mainNav.classList.add("text-white");
    mainNav.classList.remove("absolute");
    mainNav.classList.add("fixed");
    mainNav.classList.add("bottom-0");
    navToggles.forEach((navToggle) => {
      navToggle.classList.add(...navToggleOpenClasses);
      navToggle.style.setProperty("top", "calc(1rem + var(--menu-gradient-bar-height))");
      initialMenuStrokesColor = getComputedStyle(navToggle).getPropertyValue("--menu-strokes-color");
      navToggle.style.setProperty("--menu-strokes-color", "#fff");
      navToggle.classList.remove("hover:bg-bdBlue-600");
    });
    menuFull.classList.add("opacity-0");
    menuFullLinkLis.forEach((li) => {
      li.classList.add("overflow-hidden");
    });

    menuFullLinks.forEach((link, i) => {
      link.classList.remove("transition-all");
      link.classList.add("transition-none");
      link.classList.add("opacity-0");
      link.style.setProperty("--menu-link-transition-delay", `${i * 60}ms`);
      link.style.setProperty("--menu-link-translate-y", "100%");
    });

    menuFull.classList.remove("hidden");
    menuFull.classList.add("flex");
    navToggleSr.setAttribute("aria-expanded", "true");
    navToggleSr.innerText = "Close menu";
    menuCtas.classList.add("opacity-0");

    setTimeout(() => {
      if (!isMenuOpen) return;
      menuFull.classList.remove("opacity-0");
    }, 10);

    setTimeout(() => {
      if (!isMenuOpen) return;
      menuFull.classList.remove("opacity-0");
      menuFullLinks.forEach((link) => {
        link.classList.remove("transition-none");
        link.classList.remove("opacity-0");
        link.classList.add("transition-all");
        link.style.setProperty("--menu-link-translate-y", "0px");
      });
    }, 100);

    const totalFullLinkAnimationDuration = (menuFullLinks.length * 60) + 200;

    setTimeout(() => {
      menuFullLinkLis.forEach((li) => {
        li.classList.remove("overflow-hidden");
      });
    }, totalFullLinkAnimationDuration);
  } else {
    mainNav.classList.add("z-50");
    mainNav.classList.remove("z-max-1");
    if (scrollNav) scrollNav.classList.remove("hidden");

    navToggles.forEach((navToggle) => {
      navToggle.classList.remove(...navToggleOpenClasses);
      navToggle.style.setProperty("--menu-strokes-color", initialMenuStrokesColor);
      navToggle.classList.add("hover:bg-bdBlue-600");
    });

    const shouldBeFixed = window.scrollY > 0 && dropdownBackground;

    mainNav.classList.toggle("fixed", shouldBeFixed);
    mainNav.classList.toggle("absolute", !shouldBeFixed);

    if (menuColorIsLight) mainNav.classList.remove("text-white");

    menuFull.style.transition = "none";
    menuCtas.style.transition = "none";
    menuFull.classList.add("opacity-0");
    menuCtas.classList.remove("opacity-0");
    mainNav.classList.remove("bottom-0");
    menuFull.style.transition = "";
    menuCtas.style.transition = "";

    navToggleSr.setAttribute("aria-expanded", "false");
    navToggleSr.innerText = "Open menu";

    menuFullLinks.forEach((link) => {
      menuFull.style.transition = "none";
      link.classList.add("opacity-0");
      link.style.setProperty("--menu-link-translate-y", "100%");
      menuFull.style.transition = "";
    });

    setTimeout(() => {
      if (isMenuOpen) return;
      menuFull.classList.remove("flex");
      menuFull.classList.add("hidden");

      menuFullLinkLis.forEach((li) => {
        li.classList.remove("overflow-hidden");
      });
    }, 200);
  }
};

const handleScrollNav = () => {
  const hasScrolledMoreThanHalfScreen = window.scrollY > 400;

  if (hasScrolledMoreThanHalfScreen) {
    scrollNav.style.transitionDuration = "1s";
    scrollNav.style.transform = "translateY(0)";
    scrollNav.classList.remove("opacity-0");
    scrollNav.classList.remove("pointer-events-none");
  } else {
    scrollNav.style.transitionDuration = "0s";
    scrollNav.style.transform = "translateY(-150%)";
    scrollNav.classList.add("opacity-0");
    scrollNav.classList.add("pointer-events-none");
  }
};


const handleNavOnWindowResize = () => window.innerWidth >= 768 && isMenuOpen && toggleNav();
const closeMenu = () => isMenuOpen && window.innerWidth < 768 && toggleNav();
const closeMenuWithEsc = (e) => isMenuOpen && window.innerWidth < 768 && e.key === "Escape" && toggleNav();

if (navToggles.length) {
  navToggles.forEach((navToggle) => navToggle.addEventListener("click", toggleNav));
  window.addEventListener("keydown", closeMenuWithEsc);
  window.addEventListener("resize", handleNavOnWindowResize, {passive:true});
}

if (menuFullLinks.length) {
  menuFullLinks.forEach((link) => {
    link.addEventListener("click", closeMenu);
  });
}

if (scrollNav) {
  handleScrollNav();
  window.addEventListener("scroll", handleScrollNav, { passive: true });
}


// NEW NAVBAR //

const setMenuLight = (shouldSetLight = false) => {
  if (window.scrollY >= MAIN_NAV_SCROLL_THRESHOLD) shouldSetLight = true;

  if (menuColorIsLight === shouldSetLight) return;
  menuColorIsLight = shouldSetLight;

  if (shouldSetLight) {
    mainNav.classList.remove("text-white");
    mainNav.classList.add("has-dropdown", "text-black", "bg-white");
    navToggles.forEach((navToggle) => {
      navToggle.style.setProperty("--menu-strokes-color", "#000");
    });
    mainNavRingOffsetBlack.forEach((ringOffset) => {
      ringOffset.classList.remove("ring-offset-black");
      ringOffset.classList.add("ring-offset-white");
    });
  } else {
    mainNav.classList.remove("has-dropdown", "text-black", "bg-white");
    mainNav.classList.add("text-white");
    navToggles.forEach((navToggle) => {
      navToggle.style.setProperty("--menu-strokes-color", "#fff");
    });
    mainNavRingOffsetBlack.forEach((ringOffset) => {
      ringOffset.classList.remove("ring-offset-white");
      ringOffset.classList.add("ring-offset-black");
    });
  }
};

let dropdownYScrollInitial = 0;
const dropdownYScrollThresholdPx = 100;

const updateDropdownYScrollDelta = () => {
  const delta = window.scrollY - dropdownYScrollInitial;

  if (Math.abs(delta) > dropdownYScrollThresholdPx) {
    toggleDropdownClasses(openDropdownId, false);
  }
};

const toggleDropdownClasses = (dropdownId, shouldOpen = false) => {
  if (!dropdownId) return console.error("No dropdownId provided");

  // 1. Stop hiding if it's opened again
  if (dropdownId === openDropdownId && shouldOpen) {
    clearTimeout(hideDropdownTimeout);
    return;
  }

  // 2. Hide old dropdown if new one is opened
  if (openDropdownId && shouldOpen && openDropdownId !== dropdownId) {
    toggleDropdownClasses(openDropdownId, false);
  }

  if (shouldOpen) {
    openDropdownId = dropdownId;
    openedDropdownAt = new Date();
  } else if (dropdownId === openDropdownId && !shouldOpen) {
    openDropdownId = null;
    openedDropdownAt = null;
  }

  // 3. Toggle dropdown classes
  const dropdownContent = document.getElementById(dropdownId);
  const dropdownTrigger = document.getElementById(dropdownId.slice(0, -8));

  setMenuLight(shouldOpen || openDropdownId !== null);

  const makeDropdownVisible = (isVisible) => {
    dropdownContent.classList.toggle("dropdown", isVisible);
    dropdownContent.classList.toggle("hidden", !isVisible);
    if (openDropdownId && dropdownId !== openDropdownId && !isVisible) return;
    dropdownBackground.classList.toggle("hidden", !isVisible);
  };

  const animateDropdownInOut = () => {
    dropdownContent.classList.toggle("dropdown-delay", shouldOpen);
    dropdownContent.classList.toggle("opacity-100", shouldOpen);
    dropdownTrigger.classList.toggle("is-dropdown-delay", shouldOpen);
    dropdownBackground.classList.toggle("opacity-0", !shouldOpen);
  };

  if (shouldOpen) makeDropdownVisible(true);
  else animateDropdownInOut();

  const timeout = shouldOpen ? 25 : 300; // 1 render cycle VS animation duration

  setTimeout(() => {
    if (shouldOpen) animateDropdownInOut();
    else makeDropdownVisible(false);
  }, timeout);

  // 4. Add / remove scroll listener to hide dropdown
  if (shouldOpen) {
    dropdownYScrollInitial = window.scrollY;
    window.addEventListener("scroll", updateDropdownYScrollDelta, {passive: true});
  } else if (openDropdownId !== dropdownId) {
    window.removeEventListener("scroll", updateDropdownYScrollDelta);
  }
};

for (let i = 0; i < dropdownTriggers.length; i++) {
  const trigger = dropdownTriggers[i];
  const dropdownId = trigger.getAttribute("data-target");
  const dropdownContent = document.getElementById(dropdownId);

  trigger.addEventListener("click", (e) => {
    if (openDropdownId === dropdownId && window.innerWidth >= 1024 && (!openedDropdownAt || new Date() - openedDropdownAt < 600)) return e.preventDefault();
    toggleDropdownClasses(dropdownId, openDropdownId !== dropdownId);
    closeMenu();
  });

  trigger.addEventListener("mouseenter", () => {
    if (window.innerWidth < 768) return;
    toggleDropdownClasses(dropdownId, true);
  });

  dropdownContent.addEventListener("mouseenter", () => {
    clearTimeout(hideDropdownTimeout);
  });

  dropdownContent.addEventListener("mouseleave", () => {
    hideDropdownTimeout = setTimeout(() => {
      toggleDropdownClasses(dropdownId, false);
    }, DROPDOWN_TIMEOUT_DURATION);
  });
}

const closeClickablesDropdown = (e) => {
  const dropdownId = e.target.closest(".group\\/dropdown").id;
  if (!dropdownId) return;
  toggleDropdownClasses(dropdownId, false);
};

const handleMainNavScroll = () => {
  const pastThreshold = window.scrollY >= MAIN_NAV_SCROLL_THRESHOLD;

  if (pastThreshold === menuScrolledPastThreshold) return;
  menuScrolledPastThreshold = pastThreshold;

  if (pastThreshold) {
    mainNav.classList.remove("absolute");
    mainNav.classList.add("fixed", "shadow-2xl");
    setMenuLight(true);
  } else {
    mainNav.classList.remove("fixed", "shadow-2xl");
    mainNav.classList.add("absolute");
    setMenuLight(false);
  }
};

if (mainNav && dropdownBackground) {
  mainNav.addEventListener("mouseenter", () => {
    if (window.innerWidth < 768) return;
    clearTimeout(hideDropdownTimeout);
  });

  mainNav.addEventListener("mouseleave", () => {
    const targetId = openDropdownId;
    if (!targetId || window.innerWidth < 768) return;
    hideDropdownTimeout = setTimeout(() => {
      if (openDropdownId !== targetId) return;
      toggleDropdownClasses(targetId, false);
    }, DROPDOWN_TIMEOUT_DURATION);
  });

  dropdownBackground.addEventListener("click", () => {
    if (!openDropdownId) return;
    clearTimeout(hideDropdownTimeout);
    toggleDropdownClasses(openDropdownId, false);
  });

  dropdownClickables.forEach((clickable) => {
    clickable.addEventListener("click", closeClickablesDropdown);
  });

  window.addEventListener("scroll", handleMainNavScroll, { passive: true });
  window.addEventListener("resize", handleMainNavScroll, { passive: true });
  if (window.scrollY > 0) handleMainNavScroll();
}
