const pricingYearMonthToggle = document.getElementById("pricingYearMonthToggle");
const pricePayAsYouGo = document.getElementById("pricePayAsYouGo");
const priceEssentials = document.getElementById("priceEssentials");
const pricePro = document.getElementById("pricePro");
const pricingTimespans = document.querySelectorAll(".pricingTimespan");
const pricingComparisonNav = document.getElementById("pricingComparisonNav");
const pricingComparison = document.getElementById("pricingComparison");
const hideMonthlys = document.getElementsByClassName("hide-monthly");
const paymentLinks = document.getElementsByClassName("payment-link");
let pricingToggleTimeout;

const pricingComparisonObserver = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        pricingComparisonNav.classList.remove("hidden");
        pricingComparisonNav.classList.add("fixed");
        pricingComparisonNav.classList.remove("pointer-events-none");
      } else {
        pricingComparisonNav.classList.add("hidden");
        pricingComparisonNav.classList.remove("fixed");
        pricingComparisonNav.classList.add("pointer-events-none");
      }
    });
  },
  {
    threshold: 0,
    rootMargin: "0px 0px -95% 0px",
  }
);

const setPrices = (isMonthly) => {
  let paymentTerm = isMonthly ? "month" : "year";

  for (let i = 0; i < paymentLinks.length; i++) {
    let link = paymentLinks[i];
    let href = link.getAttribute("href");
    let newHref = href.replace(/(month|year)/, paymentTerm);
    link.setAttribute("href", newHref);
  }

  if (isMonthly) {
    pricePayAsYouGo.innerHTML = "$0";
    priceEssentials.innerHTML = "$185";
    pricePro.innerHTML = "$618";
    pricingTimespans.forEach((pricingTimespan) => {
      pricingTimespan.innerHTML = "monthly";
    });
  } else {
    pricePayAsYouGo.innerHTML = "$0";
    priceEssentials.innerHTML = "$125";
    pricePro.innerHTML = "$477";
    pricingTimespans.forEach((pricingTimespan) => {
      pricingTimespan.innerHTML = "annually";
    });
  }

  for (let i = 0; i < hideMonthlys.length; i++) {
    hideMonthlys[i].classList.toggle("hidden", isMonthly);
  }
};

const handlePricingYearMonthToggle = () => {
  const isMonthly = pricingYearMonthToggle.checked;
  if (pricingToggleTimeout) clearTimeout(pricingToggleTimeout);

  pricePayAsYouGo.style.transition = "0.5s ease all";
  priceEssentials.style.transition = "0.5s ease all";
  pricePro.style.transition = "0.5s ease all";

  // Translate off to the left
  pricePayAsYouGo.style.opacity = "0";
  priceEssentials.style.opacity = "0";
  pricePro.style.opacity = "0";
  pricePayAsYouGo.style.transform = "translateX(-10px)";
  priceEssentials.style.transform = "translateX(-10px)";
  pricePro.style.transform = "translateX(-10px)";

  pricingToggleTimeout = setTimeout(() => {
    // Jump to the right side, then animate in
    pricePayAsYouGo.style.transition = "none";
    priceEssentials.style.transition = "none";
    pricePro.style.transition = "none";

    pricePayAsYouGo.style.transform = "translateX(10px)";
    priceEssentials.style.transform = "translateX(10px)";
    pricePro.style.transform = "translateX(10px)";

    // Allow the browser to render the element off-screen
    requestAnimationFrame(() => {
      pricePayAsYouGo.style.transition = "0.5s ease all";
      priceEssentials.style.transition = "0.5s ease all";
      pricePro.style.transition = "0.5s ease all";

      setPrices(isMonthly);

      pricePayAsYouGo.style.opacity = "1";
      priceEssentials.style.opacity = "1";
      pricePro.style.opacity = "1";

      pricePayAsYouGo.style.transform = "translateX(0px)";
      priceEssentials.style.transform = "translateX(0px)";
      pricePro.style.transform = "translateX(0px)";
    });
  }, 500);
};

if (pricingYearMonthToggle) pricingYearMonthToggle.addEventListener("change", handlePricingYearMonthToggle);
if (pricingComparison) pricingComparisonObserver.observe(pricingComparison);
