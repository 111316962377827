const faqButtons = document.getElementsByClassName("faq-button");

const toggleFaqQuestion = (e) => {
  const button = e.currentTarget;

  // Close all other questions
  for (const otherButton of faqButtons) {
    if (otherButton !== button) {
      otherButton.querySelector(".plus").classList.remove("hidden");
      otherButton.querySelector(".minus").classList.add("hidden");
      const otherContentId = otherButton.getAttribute("aria-controls");
      const otherContent = document.getElementById(otherContentId);
      otherContent.classList.add("max-h-0");
      otherContent.classList.remove("mb-8");
    }
  }

  const plusIcon = button.querySelector(".plus");
  const minusIcon = button.querySelector(".minus");
  plusIcon.classList.toggle("hidden");
  minusIcon.classList.toggle("hidden");

  const contentId = button.getAttribute("aria-controls");
  const content = document.getElementById(contentId);

  content.classList.toggle("max-h-0");
  content.classList.toggle("mb-8");
};

for (const faqButton of faqButtons) {
  faqButton.addEventListener("click", toggleFaqQuestion);
}
