const fullscreenCompanyVideo = document.getElementById("fullscreenCompanyVideo");
const openFullscreenCompanyVideo = document.getElementsByClassName("open-fullscreen-company-video");
const closeFullscreenCompanyVideo = document.getElementsByClassName("close-fullscreen-company-video");
const videoPlayer = document.getElementById("videoPlayer");
const rewatchButton = document.getElementById("rewatchButton");
const videoContactForm = document.getElementById("videoContactForm");

let isVideoContactFormVisible = false;

const handleRewatch = () => {
  videoContactForm.classList.add("hidden");
  isVideoContactFormVisible = false;
  videoPlayer.play();
};

if (fullscreenCompanyVideo && openFullscreenCompanyVideo && closeFullscreenCompanyVideo) {
  for (let i = 0; i < openFullscreenCompanyVideo.length; i++) {
    openFullscreenCompanyVideo[i].addEventListener("click", () => {
      fullscreenCompanyVideo.classList.remove("hidden");
      setTimeout(() => !isVideoContactFormVisible && videoPlayer.play(), 500);
    });
  }

  for (let i = 0; i < closeFullscreenCompanyVideo.length; i++) {
    closeFullscreenCompanyVideo[i].addEventListener("click", () => {
      fullscreenCompanyVideo.classList.add("hidden");
      videoPlayer.pause();
    });
  }

  videoPlayer.addEventListener("ended", () => {
    videoContactForm.classList.remove("hidden");
    isVideoContactFormVisible = true;
  });

  rewatchButton.addEventListener("click", handleRewatch);
}
