const suggestedSearchContainer = document.getElementById("suggestedSearchContainer");
let suggestedSearches = [];
if (suggestedSearchContainer) suggestedSearches = suggestedSearchContainer.querySelectorAll("a");
const moreIndustries = document.getElementById("moreIndustries");

let showMoreIndustries = false;
const toggleShowMoreIndustries = () => {
  showMoreIndustries = !showMoreIndustries;
  moreIndustries.textContent = showMoreIndustries ? "Show Less" : "Show More";
  suggestedSearches.forEach((suggestedSearch, index) => {
    if (index > 10) suggestedSearch.classList.toggle("hidden");
  });
};

if (moreIndustries) moreIndustries.addEventListener("click", toggleShowMoreIndustries);
