import {debounce} from "./lightweight-debounce.js";

const searchInputs = document.querySelectorAll(".search-input");
const searchable = document.querySelectorAll(".searchable");
const searchSections = document.querySelectorAll(".search-section");

const handleSearch = (e) => {
  const searchValue = e.target.value.toLowerCase();

  searchable.forEach((el) => {
    const searchableContent = el.getAttribute("data-searchable").toLowerCase();

    if (!searchValue || searchableContent.includes(searchValue)) {
      el.style.display = "block";
    } else {
      el.style.display = "none";
    }
  });

  searchSections.forEach((section) => {
    const searchables = section.querySelectorAll(".searchable");
    const visibleSearchables = Array.from(searchables).filter((el) => el.style.display === "block");

    if (visibleSearchables.length === 0) {
      section.style.display = "none";
    } else {
      section.style.display = "block";
    }
  });
};

const debounceHandleSearch = debounce(handleSearch, 300);

if (searchInputs.length > 0) {
  searchInputs.forEach((input) => {
    input.addEventListener("input", debounceHandleSearch);
  });
}
