const targetGroupsHolder = document.querySelectorAll(".tg-holder");
const targetGroupsContent = document.querySelectorAll(".tg-content");

if (targetGroupsContent.length > 0) {
  const observerOptions = {
    root: null,
    rootMargin: "0% 0% -10% 0%",
    threshold: 0,
  };

  const observerCallback = (entries) => {
    entries.forEach(entry => {
      const isIntersecting = entry.isIntersecting;

      entry.target.classList.toggle("opacity-0", !isIntersecting);
      const targetGroupChildren = entry.target.children || [];

      for (let i = 0; i < targetGroupChildren.length; i++) {
        const child = targetGroupChildren[i];
        child.classList.toggle("opacity-0", !isIntersecting);
        child.classList.toggle("translate-y-12", !isIntersecting);
      }
    });
  };

  const observer = new IntersectionObserver(observerCallback, observerOptions);

  targetGroupsContent.forEach(targetGroup => {
    observer.observe(targetGroup);
  });
}

if (targetGroupsHolder.length > 0) {
  const observerOptions = {
    root: null,
    rootMargin: "-49% 0% -49% 0%",
    threshold: 0
  };

  const observerCallback = (entries) => {
    entries.forEach(entry => {
      const isIntersecting = entry.isIntersecting;
      entry.target.classList.toggle("opacity-40", !isIntersecting);
      entry.target.classList.toggle("scale-[0.98]", !isIntersecting);
    });
  };

  const observer = new IntersectionObserver(observerCallback, observerOptions);

  targetGroupsHolder.forEach((targetGroup) => {
    observer.observe(targetGroup);
  });
}
