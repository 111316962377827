let hasStartedAnimations = false;


/* ----------------------------------------------------------------
                         Handle Animations
 ---------------------------------------------------------------- */
const handleAnimations = () => {
  hasStartedAnimations = true;

  const gradientLines = document.querySelectorAll(".draw-gradient-line");
  const blueCircles = document.querySelectorAll(".draw-blue-circle.animated");
  const drawTrails = document.querySelectorAll(".draw-trail");
  const fadeInWhenCenteredContainers = document.querySelectorAll(".fade-in-when-centered-container");

  const fortyPercentThreshold = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) entry.target.classList.add("start-animation");
        else entry.target.classList.remove("start-animation");
      });
    },
    {
      rootMargin: "20% 0px -40% 0px"
    }
  );

  const sixtyPercentThreshold = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) entry.target.classList.add("start-animation");
        else entry.target.classList.remove("start-animation");
      });
    },
    {
      rootMargin: "20% 0px -60% 0px"
    }
  );

  const handleFadeInObserver = (entries) => {
    entries.forEach((entry) => {
      const fadeInWhenCentered = entry.target.querySelector(".fade-in-when-centered");
      if (!fadeInWhenCentered) return;

      if (entry.isIntersecting) fadeInWhenCentered.classList.remove("opacity-25");
      else fadeInWhenCentered.classList.add("opacity-25");
    });
  };

  const fadeInObserver = new IntersectionObserver(handleFadeInObserver,{ rootMargin: "-45% 0px -45% 0px" });
  const fadeInObserverTop = new IntersectionObserver(handleFadeInObserver,{ rootMargin: "-45% 0px -20% 0px" });


  if (gradientLines.length) gradientLines.forEach((gradientDrawLine) => fortyPercentThreshold.observe(gradientDrawLine));
  if (blueCircles.length) blueCircles.forEach((blueCircle) => sixtyPercentThreshold.observe(blueCircle));
  if (fadeInWhenCenteredContainers.length) fadeInWhenCenteredContainers.forEach((item, i) => {
    if (i === 0) fadeInObserverTop.observe(item);
    else fadeInObserver.observe(item);
  });

  const updateTrailRect = (svg, rect) => {
    const vh = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const boundingClient = svg.getBoundingClientRect();
    const svgTop = boundingClient.top;
    const svgHeight = boundingClient.height;
    const twoThirdsVh = vh * 0.66; // Line appears when it's in the upper 2/3 of the viewport

    /**
     * 1. If the svg isn't close to the viewport, don't update the rect (saves unnecessary calculations,
     * but gives wiggle room to ensure smooth animations on quick scrolls still work)
     */
    if ((-1 * svgTop) + vh < 0) return;
    if (svgHeight < (svgTop * -1)) return;

    // 2.Calculate the targetY based on 1/2 the viewport height and the svgTop
    const targetY = Math.max(0, twoThirdsVh + (-1 * svgTop));

    // 3. Convert the targetY to a percentage of the svgHeight, allowing us to also set the height of the rect (in %)
    const targetYPercent = targetY / svgHeight;
    const targetHeightPercent = 1 - targetYPercent;

    // 4. Make sure the targetYPercent and targetHeightPercent are between 0 and 1
    const targetYPercentBetweenZeroAndOne = Math.min(Math.max(targetYPercent, 0), 1);
    const targetHeightPercentBetweenZeroAndOne = Math.min(Math.max(targetHeightPercent, 0), 1);

    // 5. Update the rect
    rect.setAttribute("y", `${targetYPercentBetweenZeroAndOne * 100}%`);
    rect.setAttribute("height", `${targetHeightPercentBetweenZeroAndOne * 100}%`);
  };

  drawTrails.forEach(svg => {
    const rect = svg.querySelector("rect");
    updateTrailRect(svg, rect);
    window.addEventListener("scroll", () => updateTrailRect(svg, rect));
    window.addEventListener("resize", () => updateTrailRect(svg, rect));
  });
};

/* ----------------------------------------------------------------
                         Start Animations
 ---------------------------------------------------------------- */
window.addEventListener("DOMContentLoaded", () => {
  const mustLoadBeforeAnimations = document.querySelectorAll(".must-load-before-animations");

  // 1. Check if there are any images that must load before animations
  if (!mustLoadBeforeAnimations.length) return handleAnimations();

  // 2. If there are images that must load before animations, check if they are loaded
  mustLoadBeforeAnimations.forEach((img) => {
    if (img.complete) img.classList.remove("must-load-before-animations");
    else {
      img.addEventListener("load", () => {
        img.classList.remove("must-load-before-animations");
        if (document.querySelectorAll(".must-load-before-animations").length === 0) {
          if (!hasStartedAnimations) handleAnimations();
        }
      });
    }
  });

  // 3. If all images are loaded, start animations
  if (document.querySelectorAll(".must-load-before-animations").length === 0) handleAnimations();

  // 4. If images are not loaded after 3 seconds, start animations anyway
  setTimeout(() => !hasStartedAnimations && handleAnimations(), 3000);
});

// 5. Last fallback, start animations after 5 seconds
setTimeout(() => !hasStartedAnimations && handleAnimations(), 5000);
