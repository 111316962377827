const btnSwitchContent = document.querySelectorAll(".btn-switch-content");

const handleBtnClick = (btn) => {
  const parentElement = btn.parentElement;
  const groupedSwitches = parentElement.querySelectorAll(".btn-switch-content");
  const outerIndex = Array.from(groupedSwitches).indexOf(btn);

  groupedSwitches.forEach((btn, innerIndex) => {
    const isActive = outerIndex === innerIndex;

    const dataActive = btn.getAttribute("data-active");
    const dataInactive = btn.getAttribute("data-inactive");
    const dataSelector = btn.getAttribute("data-selector");

    btn.setAttribute("data-is-active", isActive ? "1" : "0");

    const dataSelectorResults = document.querySelectorAll(dataSelector);
    dataSelectorResults.forEach(el => el.classList.toggle("hidden", !isActive));

    const dataActiveClasses = dataActive.split(" ");
    const dataInactiveClasses = dataInactive.split(" ");

    dataActiveClasses.forEach(className => btn.classList.toggle(className, isActive));
    dataInactiveClasses.forEach(className => btn.classList.toggle(className, !isActive));
  });
};

const initSwitchContent = () => {
  btnSwitchContent.forEach((btn) => {
    btn.addEventListener("click", () => handleBtnClick(btn));
  });
};

if (btnSwitchContent && btnSwitchContent.length > 0) {
  initSwitchContent();
}
