const hfContainers = document.querySelectorAll(".hf-container");

const handleHfTranslateTypeScale = (entry, image, delayIn, delayOut) => {
  if (entry.intersectionRatio >= 0.2) {
    image.style.transition = `transform 250ms ${delayIn}ms ease, opacity 100ms ${delayIn}ms ease`;
    image.style.transform = `translate(${image.getAttribute("data-translate-x")}, ${image.getAttribute("data-translate-y")}) scale(1)`;
    image.style.opacity = "1";
  } else {
    image.style.transition = `transform 1s ${delayOut}ms ease, opacity 1s ${delayOut}ms ease`;
    image.style.transform = `translate(${image.getAttribute("data-translate-x")}, ${image.getAttribute("data-translate-y")}) scale(0.8)`;
    image.style.opacity = "0";
  }
};

const handleHfTranslateTypeSlideUp = (entry, image, delayIn, delayOut) => {
  if (entry.intersectionRatio >= 0.2) {
    image.style.transition = `transform 500ms ${delayIn}ms ease, opacity 500ms ${delayIn}ms ease-out`;
    image.style.transform = `translate(${image.getAttribute("data-translate-x")}, ${image.getAttribute("data-translate-y")})`;
    image.style.opacity = "1";
  } else {
    const dataTransitionYOffset = image.getAttribute("data-transition-y-offset");

    image.style.transition = `transform 1s ${delayOut}ms ease, opacity 1s ${delayOut}ms ease`;
    image.style.transform = `translate(${image.getAttribute("data-translate-x")}, calc(${image.getAttribute("data-translate-y")} + ${dataTransitionYOffset})) scale(0.9)`;
    image.style.opacity = "0";
  }
};

const handleHfContainerIntersect = (entries) => {
  entries.forEach(entry => {
    const container = entry.target;

    const intersecting = entry.intersectionRatio >= 0.2;
    const images = container.querySelectorAll(".hf-image-container img");

    container.classList.toggle("opacity-0", !intersecting);
    container.classList.toggle("scale-50", !intersecting);

    for (let i = 0; i < images.length; i++) {
      const image = images[i];

      const index = parseInt(image.getAttribute("data-index"));
      const total = parseInt(image.getAttribute("data-total"));
      const delayIn = (index + 1) * 150;
      const delayOut = (total - index) * 150;

      const dataTranslateType = image.getAttribute("data-translate-type");

      switch (dataTranslateType) {
      case "slide-up":
        handleHfTranslateTypeSlideUp(entry, image, delayIn, delayOut);
        break;
      default:
        handleHfTranslateTypeScale(entry, image, delayIn, delayOut);
        break;
      }
    }
  });
};

const hfObserverOptions = {
  root: null,
  rootMargin: "-10% 0% -5% 0%",
  threshold: 0.2
};

let hfContainerObserver = new IntersectionObserver(handleHfContainerIntersect, hfObserverOptions);
if (hfContainers.length) hfContainers.forEach(container => hfContainerObserver.observe(container));
